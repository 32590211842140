
window.is_mac_os = function(){
    return (navigator.userAgent.indexOf("Macintosh")) > -1;
};

//to avoid depending on script load order, use a function instead
window.usingCustomComponents = function(){
    return typeof(window.enableCustomComponents) !== 'undefined' && window.enableCustomComponents === true;
};

window.isSelect2Loaded = function(){
    return typeof($.fn.select2) !== 'undefined';
};


$(document).ready(function(){

    if(window.is_mac_os()){
        $("body").addClass("browser-mac-os");
    }

    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover();


    //sync nav-item and nav-link .active classes
    $('.nav-tabs .nav-item .nav-link.active').each(function(i, el){
        $(el).parent().addClass('active');
    });
    $('.nav-tabs .nav-item.active').each(function(i, el){
        $(this).find('.nav-link').addClass('active');
    });
    $('body').on('click', '.nav-tabs .nav-item .nav-link', function(){
        var $p = $(this).parent();
        $p.siblings().each(function(i, sib){
            $(sib).removeClass('active');
            $(sib).find('.nav-link').removeClass('active');
        });
        $p.addClass('active');
    });


});
